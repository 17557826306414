import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // eslint-disable-next-line no-undef

  $(document).scroll(function () {
    const scroll = $(this).scrollTop()
    if ($(window).width() >= 991 && scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })

  const secondNavWrapper = document.querySelector('.header')

  if (secondNavWrapper && $(window).width() >= 1200) {
    const wrapperHeight = secondNavWrapper.offsetHeight

    $('.second-nav--wrapper').css('top', (wrapperHeight))
  }

  $('.card-header.readmore').click(function () {
    $(this).prev().toggle()
    // eslint-disable-next-line eqeqeq
    if ($(this).text() == 'Mehr erfahren') {
      $(this).text('weniger')
    } else {
      $(this).text('Mehr erfahren')
    }
  })

  const cards = document.querySelectorAll('.card')

  cards.forEach(function (card) {
    const cardHeader = card.querySelector('.card-header')
    const cardCollapse = card.querySelector('.card-collapse')

    if (cardHeader.getAttribute('aria-expanded') === 'true') {
      cardCollapse.classList.add('show')
    }
  })
})

// recursive function
function addOpenClassToParents (element) {
  const parent = element.parentElement
  if (parent && parent.tagName.toLowerCase() === 'li') {
    parent.classList.add('open')
  }
  if (parent) {
    if (!parent.classList.contains('second-nav--wrapper')) {
      addOpenClassToParents(parent)
    }
  }
}

function loadMobileNavigationLocation () {
  // open nav on current location
  const currentPath = document.querySelector('li > .current')
  const currentPath2 = document.querySelector('li.current')
  const activeElements = document.querySelectorAll('.active.nav-expand-element')
  if (currentPath) {
    addOpenClassToParents(currentPath)
  }
  if (currentPath2) {
    currentPath2.classList.add('open')
  }
  activeElements.forEach(function (element) {
    element.classList.add('open')
  })
}

document.addEventListener('DOMContentLoaded', function () {
  if (window.matchMedia('(max-width: 1199.98px)').matches) {
    console.log('Match Mobil')
    // close burger menu if click on id to show target
    $('.header-nav-list li a').click(function () {
      $('.header-nav-content').collapse('hide')
    })

    // mobile navigation expand element
    const navExpandElements = document.querySelectorAll('.nav-expand-element')
    navExpandElements.forEach(function (item) {
      item.addEventListener('click', function (event) {
        console.log('Press: ' + item.querySelector('div').innerHTML)
        // Stops event forwarding to parent list element
        event.stopPropagation()
        const containOpen = item.querySelectorAll('.open')
        if (item.classList.contains('open')) {
          if (containOpen) {
            containOpen.forEach(function (subitem) {
              subitem.classList.remove('open')
            })
          }
          item.classList.remove('open')
        } else {
          item.classList.add('open')
        }
      })
    })
    loadMobileNavigationLocation()
  } else {
    // desktop navigation
    const menuItems1Level = document.querySelectorAll('.header-nav-list > li')
    const menuItems2Level = document.querySelectorAll('.second-level > li')
    const menuItems3Level = document.querySelectorAll('.third-level > li')
    const menuItems4Level = document.querySelectorAll('.fourth-level > li')
    const headerContainer = document.querySelector('.header')
    menuItems1Level.forEach(function (item) {
      item.addEventListener('mouseenter', function () {
      // Entferne 'active' Klasse von allen Menüpunkten
        menuItems1Level.forEach(function (otherItem) {
          otherItem.classList.remove('open')
        })
        menuItems2Level.forEach(function (otherItem) {
          otherItem.classList.remove('open')
        })
        menuItems3Level.forEach(function (otherItem) {
          otherItem.classList.remove('open')
        })
        menuItems4Level.forEach(function (otherItem) {
          otherItem.classList.remove('open')
        })
        this.classList.add('open')
      })
      headerContainer.addEventListener('mouseleave', function () {
        menuItems1Level.forEach(function (otherItem) {
          otherItem.classList.remove('open')
        })
      })
    })
    menuItems2Level.forEach(function (item) {
      item.addEventListener('mouseenter', function () {
        // Entferne 'active' Klasse von allen Menüpunkten
        menuItems2Level.forEach(function (otherItem) {
          otherItem.classList.remove('open')
        })
        this.classList.add('open')
      })
    })
    menuItems3Level.forEach(function (item) {
      item.addEventListener('mouseenter', function () {
        // Entferne 'active' Klasse von allen Menüpunkten
        menuItems3Level.forEach(function (otherItem) {
          otherItem.classList.remove('open')
        })
        this.classList.add('open')
      })
    })
    menuItems4Level.forEach(function (item) {
      item.addEventListener('mouseenter', function () {
        // Entferne 'active' Klasse von allen Menüpunkten
        menuItems4Level.forEach(function (otherItem) {
          otherItem.classList.remove('open')
        })
        this.classList.add('open')
      })
    })
    // Level 5 navigation background fix
    document.querySelectorAll('.fourth-level--wrapper').forEach(function (level4Item) {
      level4Item.addEventListener('mouseenter', function (event) {
        event.stopPropagation()
        const style = window.getComputedStyle(level4Item)
        const diff = parseFloat(style.marginTop) + parseFloat(style.marginBottom)
        event.target.querySelectorAll('.fifth-level--wrapper').forEach(function (fifthItem) {
          fifthItem.style.top = (level4Item.clientHeight - diff >= 0 ? level4Item.clientHeight - diff : 0) + 'px'
        })
      })
    })
  }
  // Add clicl Event to navbar toggler
  const burgerIcon = document.querySelector('.navbar-toggler')
  burgerIcon.addEventListener('click', loadMobileNavigationLocation)
})

// eslint-disable-next-line no-new, no-undef
const gliderElement = document.querySelector('.glider.active .neos-contentcollection')

if (gliderElement) {
  const glider = new Glider(gliderElement, {
    slidesToShow: 1,
    slidesToScroll: 1,
    startAt: 1,
    draggable: true, // Set draggable to true to enable touch events
    arrows: {
      prev: '.glider-prev',
      next: '.glider-next'
    }
  })

  updateSliderInfo(glider)

  // Add event listeners for click events
  document.querySelector('.glider-prev').addEventListener('click', function () {
    updateSliderInfo(glider)
  })

  document.querySelector('.glider-next').addEventListener('click', function () {
    updateSliderInfo(glider)
  })

  // Add event listeners for touch events
  document.querySelector('.glider-prev').addEventListener('click', function () {
    updateSliderInfo(glider)
  })

  document.querySelector('.glider-next').addEventListener('click', function () {
    updateSliderInfo(glider)
  })

  function updateSliderInfo (glider) {
    const totalItems = glider.slides.length
    let currentItem = glider.slide + 1

    if (currentItem > totalItems) {
      currentItem = totalItems
    }

    $('#totalItems').text(totalItems)
    $('#currentItem').text(currentItem)
  }
}

function initTabs () {
  // Finde alle Tab-Container auf der Seite
  const tabContainers = document.querySelectorAll('.tab--wrapper')

  // Iteriere durch alle Tab-Container
  tabContainers.forEach((tabContainer) => {
    // Finde die Tab-Links und Tab-Panes innerhalb des aktuellen Tab-Container
    const tabLinks = tabContainer.querySelectorAll('.nav-link')
    const tabContents = tabContainer.querySelectorAll('.tab-pane')

    // Füge einen Klick-Event-Listener zu allen Tab-Links in diesem Tab-Container hinzu
    tabLinks.forEach((tabLink, index) => {
      tabLink.addEventListener('click', function (e) {
        e.preventDefault()

        tabLinks.forEach((link) => {
          link.classList.remove('active')
        })

        this.classList.add('active')

        tabContents.forEach((content) => {
          content.classList.remove('show', 'active')
        })

        tabContents[index].classList.add('show', 'active')
      })
    })

    const firstTabLink = tabLinks[0]
    if (firstTabLink) {
      firstTabLink.click()
    }
  })
}
initTabs()

// Funktion zum ändern der Tab-Items nach URL Parameter

$(document).ready(function () {
  function processHashChange () {
    let tabContainerCounter = 1

    function generateUniqueId () {
      return tabContainerCounter++
    }

    const hash = window.location.hash
    const hashParts = hash.substring(1).split('-')
    const containerId = hashParts[0]
    const tabPaneId = hashParts.slice(1).join('-')

    $('.tab--wrapper').each(function () {
      const uniqueId = generateUniqueId()
      $(this).addClass('tabContainer' + uniqueId)
      $(this).attr('id', 'tabContainer' + uniqueId)
    })
    if (containerId) {
      $('.' + containerId).each(function () {
        $('.' + containerId + ' .tab-pane').removeClass('show active')
        $('.' + containerId + ' #' + tabPaneId).addClass('show active')
        $('.' + containerId + ' .nav-link').removeClass('active')
        $('.' + containerId + ' .nav-link[href="#' + tabPaneId + '"]').addClass('active')
      })

      // Prüfen, ob sich das Container-Element bereits oben auf der Seite befindet
      if ($(window).scrollTop() !== $('.' + containerId).offset().top - 200) {
        const containerOffset = $('.' + containerId).offset().top - 200
        $('html, body').animate({
          scrollTop: containerOffset
        }, 500)
      }
    }
  }

  // Initialer Aufruf der Funktion zur Verarbeitung des Hash-Werts
  processHashChange()

  // Event-Listener für Änderungen des Hash-Werts der URL
  $(window).on('hashchange', function () {
    processHashChange()
  })
})

// Service Items

$(document).ready(function () {
  $('.content-item').hover(function () {
    $(this).addClass('active')
    $(this).find('.content-item-popup').addClass('active')
  }, function () {
    $(this).removeClass('active')
    $(this).find('.content-item-popup').removeClass('active')
  })
})

// Linie für Service-Items

$(window).on('load resize', function () {
  function calculateLinePosition (elementOne, elementTwo, startAnchor, endAnchor) {
    const $elementOne = $(elementOne)
    const $elementTwo = $(elementTwo)

    const rect1 = $elementOne[0].getBoundingClientRect()
    const rect2 = $elementTwo[0].getBoundingClientRect()

    let x1, y1, x2, y2

    // Bestimmen Sie die Startposition basierend auf dem Ankerpunkt
    switch (startAnchor) {
      case 'top':
        x1 = rect1.left + rect1.width / 2
        y1 = rect1.top
        break
      case 'bottom':
        x1 = rect1.left + rect1.width / 2
        y1 = rect1.bottom
        break
      case 'left':
        x1 = rect1.left
        y1 = rect1.top + rect1.height / 2
        break
      case 'right':
        x1 = rect1.right
        y1 = rect1.top + rect1.height / 2
        break
      case 'top-left':
        x1 = rect1.left
        y1 = rect1.top
        break
      case 'top-right':
        x1 = rect1.right
        y1 = rect1.top
        break
      case 'bottom-left':
        x1 = rect1.left
        y1 = rect1.bottom
        break
      case 'bottom-right':
        x1 = rect1.right
        y1 = rect1.bottom
        break
      default:
        // Standard: Mittelpunkt
        x1 = rect1.left + rect1.width / 2
        y1 = rect1.top + rect1.height / 2
    }

    // Bestimmen Sie die Endposition basierend auf dem Ankerpunkt
    switch (endAnchor) {
      case 'top':
        x2 = rect2.left + rect2.width / 2
        y2 = rect2.top
        break
      case 'bottom':
        x2 = rect2.left + rect2.width / 2
        y2 = rect2.bottom
        break
      case 'left':
        x2 = rect2.left
        y2 = rect2.top + rect2.height / 2
        break
      case 'right':
        x2 = rect2.right
        y2 = rect2.top + rect2.height / 2
        break
      case 'top-left':
        x2 = rect2.left
        y2 = rect2.top
        break
      case 'top-right':
        x2 = rect2.right
        y2 = rect2.top
        break
      case 'bottom-left':
        x2 = rect2.left
        y2 = rect2.bottom
        break
      case 'bottom-right':
        x2 = rect2.right
        y2 = rect2.bottom
        break
      default:
        // Standard: Mittelpunkt
        x2 = rect2.left + rect2.width / 2
        y2 = rect2.top + rect2.height / 2
    }

    return {
      x1: x1 + window.scrollX,
      y1: y1 + window.scrollY,
      x2: x2 + window.scrollX,
      y2: y2 + window.scrollY
    }
  }

  const elementPairs = [
    {
      start: '#index-0',
      end: '#index-1',
      startAnchor: 'top-right',
      endAnchor: 'left',
      targetClass: '.index-0 .before',
      transformOrigin: 'right center'
    },
    {
      start: '#index-0',
      end: '#index-4',
      startAnchor: 'bottom',
      endAnchor: 'top',
      targetClass: '.index-0 .after',
      transformOrigin: 'right center'
    },
    {
      start: '#index-4',
      end: '#index-1',
      startAnchor: 'top-right',
      endAnchor: 'bottom-left',
      targetClass: '.index-4 .before',
      transformOrigin: 'right center'
    },
    {
      start: '#index-4',
      end: '#index-5',
      startAnchor: 'right',
      endAnchor: 'left',
      targetClass: '.index-4 .after',
      transformOrigin: 'right center'
    },
    {
      start: '#index-5',
      end: '#index-0',
      startAnchor: 'top',
      endAnchor: 'bottom-right',
      targetClass: '.index-5 .before',
      transformOrigin: 'right center'
    },
    {
      start: '#index-5',
      end: '#index-3',
      startAnchor: 'top-right',
      endAnchor: 'left',
      targetClass: '.index-5 .after',
      transformOrigin: 'right center'
    },
    {
      start: '#index-6',
      end: '#index-0',
      startAnchor: 'top-left',
      endAnchor: 'right',
      targetClass: '.index-6 .before',
      transformOrigin: 'left center',
      turnLeft: true
    },
    {
      start: '#index-6',
      end: '#index-7',
      startAnchor: 'right',
      endAnchor: 'left',
      targetClass: '.index-6 .after',
      transformOrigin: 'right center'
    },
    {
      start: '#index-7',
      end: '#index-3',
      startAnchor: 'top-left',
      endAnchor: 'bottom-right',
      targetClass: '.index-7 .before',
      transformOrigin: 'left center',
      turnLeft: true
    },
    {
      start: '#index-7',
      end: '#index-2',
      startAnchor: 'top',
      endAnchor: 'bottom',
      targetClass: '.index-7 .after',
      transformOrigin: 'right center'
    },
    {
      start: '#index-2',
      end: '#index-3',
      startAnchor: 'bottom-left',
      endAnchor: 'top-right',
      targetClass: '.index-2 .before',
      transformOrigin: 'left center',
      turnLeft: true
    },
    {
      start: '#index-2',
      end: '#index-1',
      startAnchor: 'left',
      endAnchor: 'right',
      targetClass: '.index-2 .after',
      transformOrigin: 'left center',
      turnLeft: true
    },
    {
      start: '#index-3',
      end: '#index-6',
      startAnchor: 'bottom',
      endAnchor: 'top',
      targetClass: '.index-3 .after',
      transformOrigin: 'right center'
    },
    {
      start: '#index-1',
      end: '#index-3',
      startAnchor: 'bottom-right',
      endAnchor: 'top-left',
      targetClass: '.index-1 .after',
      transformOrigin: 'right center'
    }
  ]

  elementPairs.forEach(pair => {
    const startElements = $(pair.start)
    const endElements = $(pair.end)

    startElements.each(function (index, startElement) {
      const endElement = endElements.eq(index)
      const linePosition = calculateLinePosition(startElement, endElement, pair.startAnchor, pair.endAnchor)
      const lineLength = Math.sqrt(
        (linePosition.x2 - linePosition.x1) ** 2 +
        (linePosition.y2 - linePosition.y1) ** 2
      )

      // Erstellen Sie eine Linie für jedes Paar von Start- und Endelementen
      const line = $(pair.targetClass) // Hier sollte die CSS-Klasse "line" definiert sein
      line.css({
        width: (lineLength + 5) + 'px',
        transformOrigin: pair.transformOrigin,
        transform: pair.turnLeft ? `rotate(${Math.atan2(linePosition.y2 - linePosition.y1, linePosition.x2 - linePosition.x1 - 2)}rad)` : `rotate(${Math.atan2(linePosition.y1 - linePosition.y2, linePosition.x1 - linePosition.x2 - 2)}rad)` // Verwenden Sie die entsprechende Transformation basierend auf der Bedingung
      })

      // Fügen Sie die Linie dem Zielauswahlkriterium hinzu (targetClass)
      const targetElements = startElements.find(pair.targetClass)
      targetElements.append(line)
    })
  })
// eslint-disable-next-line func-call-spacing
})

// eslint-disable-next-line no-unexpected-multiline
const items = document.querySelectorAll('.neos-contentcollection .time-line--item')

// check if an element is in viewport
// http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

function callbackFunc () {
  for (let i = 0; i < items.length; i++) {
    if (isElementInViewport(items[i])) {
      items[i].classList.add('in-view')
    }
  }
}
// listen for events
window.addEventListener('load', callbackFunc)
window.addEventListener('resize', callbackFunc)
window.addEventListener('scroll', callbackFunc)

const newsSections = document.querySelectorAll('.news-section')

newsSections.forEach(section => {
  const loadmore = section.querySelector('.btn-green-course.load-more')
  if (!loadmore) return

  let currentItems = 10
  const container = section.querySelector('.news-collection-shortlist--wrapper')
  const elementList = [...container.querySelectorAll('.news-teaser--item')]
  const loadmoreAllButton = section.querySelector('.btn-green-course.load-all')

  for (let i = 0; i < Math.min(currentItems, elementList.length); i++) {
    elementList[i].classList.add('show')
  }

  if (elementList.length <= 10) {
    loadmore.style.display = 'none'
  }

  loadmore.addEventListener('click', (e) => {
    container.classList.add('collapse')

    for (let i = currentItems; i < Math.min(currentItems + 10, elementList.length); i++) {
      elementList[i].classList.add('show')
    }
    currentItems += 10

    if (currentItems >= elementList.length) {
      loadmore.style.display = 'none'
      loadmoreAllButton.style.display = 'inline-block'
    }
  })

  loadmoreAllButton.addEventListener('click', () => {
    elementList.forEach((element, index) => {
      if (index >= 3) {
        element.classList.remove('show')
      }
    })

    currentItems = 3
    for (let i = 0; i < Math.min(currentItems, elementList.length); i++) {
      elementList[i].classList.add('show')
    }

    loadmore.style.display = 'inline-block'
    loadmoreAllButton.style.display = 'none'

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: container.offsetTop - 80
    })
  })
})

window.addEventListener('scroll', function () {
  const elements = document.getElementsByClassName('schedule-info')

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i]
    const isSticky = isElementSticky(element)

    if (isSticky) {
      element.classList.add('is-sticky')
    } else {
      element.classList.remove('is-sticky')
    }
  }
})

function isElementSticky (element) {
  const bounding = element.getBoundingClientRect()
  return bounding.top <= 160
}
